<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted my-4">
            <small v-if="signIn">Sign in with credentials</small>
            <small v-else>Reset your password</small>
          </div>

          <b-form @submit.prevent="onSubmit">
            <b-input-group class="my-2 mr-sm-2 mb-sm-0">
              <base-input
                class="input-group-alternative mb-2"
                id="input-1"
                addon-left-icon="ni ni-email-83"
                v-model="model.email"
                type="email"
                placeholder="Email"
                required
              ></base-input>
            </b-input-group>
            <b-input-group class="my-2 mr-sm-2 mb-sm-0" v-if="signIn">
              <base-input
                class="input-group-alternative"
                id="input-1"
                v-model="model.password"
                addon-left-icon="ni ni-lock-circle-open"
                type="password"
                placeholder="Password"
                required
              ></base-input>
            </b-input-group>

            <div class="text-center mt-2">
              <b-button
                type="submit"
                class="my-4"
                variat="primary"
                :disabled="authLoader"
              >
                <span v-if="signIn">
                  <b-spinner small v-if="authLoader" />
                  <span v-else> Sign in</span>
                </span>
                <span v-else>
                  <b-spinner small v-if="this.resetLoader" />
                  <span v-else> Reset</span>
                </span>
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-right">
          <small
            v-if="signIn"
            @click="signIn = !signIn"
            class="text-light cursor-pointer"
            >Forgot password?</small
          >
          <small
            v-else
            @click="signIn = !signIn"
            class="text-light cursor-pointer"
          >
            Sign In?</small
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      signIn: true,
    };
  },
  methods: {
    ...mapActions(["logIn"]),
    onSubmit() {
      if (this.signIn) {
        this.logIn(this.model);
      } else {
        this.resetPassword(this.model.email);
      }
    },
  },
  watch: {
    loggedIn() {
      if (this.loggedIn) {
        this.$router.push("/dashboard");
      }
    },
  },
  computed: mapGetters(["loggedIn"]),
};
</script>
<style scoped>
*:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
